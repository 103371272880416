<template>
	<div>
		<el-dialog title="同步订单" @close="cancel()" :visible.sync="dialog.syncOrderDialog" width="25%" center >
			<div class="opts_wrap" v-loading="loading">
				<div class="opts_line">
					<p class="txt">
						<span class="label">选择店铺：</span>
						<span class="val">
							<el-select size="small" class="" v-model="form.shop_id" placeholder="请选择" clearable>
								<el-option v-for="(item,index) in shopList" :key="index" :label="'['+item.shop_type_text+']'+item.shop_name" :value="item.shop_id">
								</el-option>
							</el-select>
						</span>
					</p>
				</div>
				<div class="opts_line">
					<p class="txt">
						<span class="label">选择时间：</span>
						<span class="val">
							<el-date-picker class=""
								v-model="form.add_date"
								type="datetimerange"
								align="right"
								unlink-panels
								range-separator="至"
								start-placeholder="开始日期"
								end-placeholder="结束日期"
								value-format="yyyy-MM-dd HH:mm:ss"
								:picker-options="pickerOptions"
								:default-time="['00:00:00', '23:59:59']">
							</el-date-picker>
						</span>
					</p>
				</div>
				
				<div class="btn_wrap">
					<p class="btn" @click="bindSubmit()">同步</p>
					<p class="btn" @click="cancel()">关闭</p>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import Vue from 'vue';
import { mapActions,mapState,mapMutations } from "vuex";
export default {
	name: "orderAddress",
	props: {
		isLoading: {
			type: Boolean,
			default: false
		},
		show: {
			type: Boolean,
			default: false
		},
		order_id: {
			type: Number,
			default: 0
		},
	},
	data() {
		return {
			dialog: {
				syncOrderDialog:false,
			},
			shopList:'',
			loading: false,
			form:{},
			pickerOptions: {
				shortcuts: [{
					text: '最近一周',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近一个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近三个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}]
			},
		};
	},
	
	created(){
	},
	mounted() {
	},
	
	computed:{
		...mapState({
			
		}),
	},
	watch:{
		show(val) {
			if(val){
				this.shopList = []
				this.init()
			}
		}
	},
	
	methods: {
		...mapActions({
			getOrderManageShopList:"ucenter/getOrderManageShopList",
			postEditSyncOrderQueue:"ucenter/postEditSyncOrderQueue",
		}),
		init(){
			this.loading = true
			this.getOrderManageShopList({data:{},success:(res)=>{
				if(res.code===200){
					this.shopList = res.data
					this.dialog.syncOrderDialog = true
				} else {
					this.$message({message: res.msg,type: 'error',})
					this.$emit('close', {})
				}
				this.loading = false
			}})
		},
		
		bindSubmit(){
			console.log(this.form)
			if(this.loading){
				return
			}
			this.loading = true
			this.postEditSyncOrderQueue({data:this.form,success:(res)=>{
				if(res.code===200){
					this.$message({message: res.msg,type: 'success'})
					this.$emit('close', {})
					this.dialog.syncOrderDialog = false
					this.form = {}
				} else {
					this.$message({message: res.msg,type: 'error',})
					this.$emit('close', {})
				}
				this.loading = false
			}})
	
		},
		cancel(){
			this.$emit('close', {})
			this.dialog.syncOrderDialog = false
		}
	},

}
</script>

<style scoped>
	.opts_wrap{padding-top: 14px;}
	.opts_wrap .opts_line{margin-top: 20px;display: flex;align-items: center;}
	.opts_line .txt{width: 100%;font-size: 16px;display: flex;align-items: center;}
	.opts_line .txt .label{min-width: 80px;display: inline-block;}
	.opts_wrap .btn_wrap{margin: 40px auto 0 auto;display: flex;align-items: center;justify-content: center;}
	.opts_wrap .btn_wrap .btn{width: 80px;height: 34px;border-radius: 4px;display: flex;justify-content: center;align-items: center;cursor: pointer;box-sizing: border-box;font-size: 14px;}
	.opts_wrap .btn_wrap .btn:nth-child(1){background: var(--red);color: #fff;margin-right: 14px;}
	.opts_wrap .btn_wrap .btn:nth-child(2){background: #fff;color: #333;border: 1px solid #ddd;margin-right: 14px;}
	.ml8{margin-left: 8px;}
	.opts_wrap .btn_wrap .btn:nth-child(3){background: #fff;color: #333;border: 1px solid #ddd;}
	.w80{width: 80px;}
	.el-icon-document-copy{cursor: pointer;}
	
	.el-input--suffix .el-input__inner{padding: 0 0 0 12px !important;font-size: 16px;}
	/*red border color*/
	.el-input__inner:focus ,.el-select .el-input.is-focus .el-input__inner ,.el-select .el-input__inner:focus ,.el-radio__input.is-checked .el-radio__inner ,.el-radio__inner:hover{border-color: var(--red);}
	
	/*red color*/
	.el-select-dropdown__item.selected ,.el-radio__input.is-checked+.el-radio__label{color: var(--red);}
	
	/*red background color*/
	.el-radio__input.is-checked .el-radio__inner{background-color: var(--red);}
	.el-date-editor{margin-top: unset;}
</style>

<template>
	<div class="cg_mask" :class="is_cg?'active' : ''" @click.self="closeConnectGood">
		<div class="cg_main" v-loading="viewLoading">
			<i class="iconfont icon-guanbi" @click.self="closeConnectGood"></i>
			<p class="title">关联商品</p>
			<div class="table_wrap">
				<div class="tabel_head bg1">
					<div class="cell">商品链接</div>
					<div class="cell">操作</div>
				</div>
				<div class="tabel_line">
					<div class="cell"><el-input v-model="inputLinks" clearable></el-input></div>
					<div class="cell"><el-button size="mini" plain @click="getConfig">获取规格</el-button></div>
				</div>
			</div>
			<div class="table_wrap">
				<div class="tabel_head bg2">
					<div class="cell">抖店产品属性</div>
					<div class="cell">更改关联产品</div>
				</div>
				<div class="tabel_line">
					<div class="cell">{{goodData.spec_value}}</div>
					<div class="cell">
						<el-select v-model="sel_product_sku_id" placeholder="请选择" clearable>
							<el-option
								v-for="(item,index) in current_sku" :key="index"
								:label="item.show_spec"
								:value="item.product_sku_id">
							</el-option>
						</el-select>
					</div>
				</div>
			</div>
			<div class="btn_wrap">
				<p class="btn" @click="submit">确定</p>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue';
import { mapActions,mapState,mapMutations } from "vuex";
export default {
	name: "connectGood",
	props: {
		dataArr:{
			type: String,
		},
	},
	data() {
		return {
			viewLoading:false,
			is_cg:false,
			goodData:{},
			inputLinks:"",
			usedLinks:[],
			sel_product_sku_id:"",
			sel_product_sku:{},
			current_sku:[]
		};
	},
	
	created(){
		
	},
	mounted() {
	},
	computed:{
		...mapState({
			
		}),
	},
	methods: {
		...mapActions({
			GetGoodsConfigData:"ucenter/GetGoodsConfigData",
			doSubmitRelative:"ucenter/doSubmitRelative",
			doOrderManageChangeSku:"ucenter/doOrderManageChangeSku",
			opOrderManageCheckoutChangeSku:"ucenter/opOrderManageCheckoutChangeSku",
		}),
		getConfig(){//获取规格
			let url = this.inputLinks
			this.viewLoading = true
			if(!url){
				this.$message({message: '请输入商品链接！',type: 'error',});
				this.viewLoading = false
				return false
			}
			for(let i in this.usedLinks){
				if(this.usedLinks[i] == url){
					this.$message({message: '该链接已关联~',type: 'warning',});
					this.viewLoading = false
					return false
				}
			}
			this.GetGoodsConfigData({data:{url},success:(res)=>{
				if(res.code===200){
					if(this.usedLinks.length>0){
						this.current_sku = this.current_sku.concat(res.data.sku)
					}else{
						this.current_sku = res.data.sku
					}
					for(let b in this.current_sku){
						if(this.goodData.spec_value == this.current_sku[b].spec_value){
							this.sel_product_sku_id = this.current_sku[b].product_sku_id
							this.sel_product_sku = this.current_sku[b]
							break
						}
					}
					for(let c in this.current_sku){
						this.current_sku[c].show_spec = ''
						this.current_sku[c].show_spec = this.current_sku[c].spec_value + '(￥' + this.current_sku[c].price + ')'
					}
					this.usedLinks.push(url)
					this.viewLoading = false
					// this.$message({message: res.msg,type: 'success',});
				} else {
					this.$message({message: res.msg,type: 'error',});
					this.viewLoading = false
				}
			}})
		},
		submit(){//关联规格
			let that = this
			if(!this.sel_product_sku_id){
				this.$message({message: '请选择关联产品！',type: 'error',});
				return false
			}
			let param = {
				id:that.goodData.id,
				order_id:that.goodData.order_id,
				product_sku_id:that.sel_product_sku_id
			}
			that.opOrderManageCheckoutChangeSku({data:param,success:(res)=>{
				if(res.code===200){
					let order_product_count = res.data.order_product_count
					let change_total_price = res.data.change_total_price
					const h = that.$createElement;
					that.$msgbox({
						title: '消息',
						message: h('p', null, [
							h('span', null, '此操作将同步关联所有该商品sku的未支付、已支付，待发货的订单，总计'),
							h('i', { style: 'color: #ff0000' }, order_product_count+'单'),
							h('span', null, '，合计'+(change_total_price>0?'退':'补 ')),
							h('i', { style: 'color: #ff0000' }, (change_total_price>0?change_total_price:-change_total_price)+'元'),
							h('span', null, '，是否确认操作？'),
						]),
						showCancelButton: true,
						confirmButtonText: '确定',
						cancelButtonText: '取消',
					}).then(action => {
						that.doOrderManageChangeSku({data:param,success:(res)=>{
							if(res.code===200){
								console.log('do submit success',res)
								that.closeConnectGood()
								location.reload();
								that.$message({message: res.msg,type: 'success',});
							} else {
								that.$message({message: res.msg,type: 'error',});
								that.viewLoading = false
							}
						}})
					}).catch(e => {
					});
				} else {
					that.$message({message: res.msg,type: 'error',});
				}
			}})
			
			
			
			
		},
		closeConnectGood(){//关闭弹窗
			this.is_cg = false
			this.clearData()
		},
		showConnectGood(){//打开弹窗
			this.is_cg = true
			setTimeout(()=>{
				this.goodData = JSON.parse(this.dataArr)
				this.inputLinks = this.goodData.product_link
				this.getConfig()
			},200)
		},
		clearData(){//清空数据
			this.goodData = {}
			this.inputLinks = ""
			this.usedLinks = []
			this.sel_product_sku_id = ""
			this.sel_product_sku = {}
			this.current_sku = []
		},
	},

}
</script>

<style scoped>
.cg_mask{width: 100%;height: 100%;position: fixed;top: 0;left: 0;background: rgba(0, 0, 0, .5);z-index: 999;transition: all .5s;visibility: hidden;opacity: 0;}
.cg_mask.active{visibility: visible;opacity: 1;}
.cg_mask .cg_main{width: 800px;height: 360px;box-sizing: border-box;padding: 20px 30px 40px;background: #fff;position: absolute;top: 50%;left: 50%;transform: translate(-50%,-70%);visibility: hidden;opacity: 0;transition: all .5s;}
.cg_mask.active .cg_main{transform: translate(-50%,-50%);visibility: visible;opacity: 1;}
.cg_main .icon-guanbi{font-size: 12px;color: #999;position: absolute;top: 16px;right: 16px;cursor: pointer;z-index: 222;}
.cg_main .title{font-size: 20px;margin-bottom: 6px;}
.table_wrap{font-size: 12px;margin-top: 12px;}
.table_wrap .tabel_head{width: 100%;height: 36px;text-align: center;display: flex;}
.table_wrap .tabel_head.bg1{background: #fff6f7;}
.table_wrap .tabel_head.bg2{background: #f5f5f5;}
.table_wrap .tabel_line{padding: 6px 0;display: flex;text-align: center;}
.table_wrap .cell{padding: 4px 10px;display: flex;justify-content: center;align-items: center;box-sizing: border-box;text-align: left;}
.table_wrap .cell:nth-child(1){width: 60%;}
.table_wrap .cell:nth-child(2){width: 40%;}
.btn_wrap{margin-top: 20px;display: flex;justify-content: center;}
.btn_wrap .btn{width: 150px;height: 36px;border-radius: 4px;display: flex;justify-content: center;align-items: center;margin-left: 20px;cursor: pointer;font-size: 12px;box-sizing: border-box;background: var(--red);color: #fff;}

</style>
<style>

.table_wrap .el-input__inner{height: 36px;line-height: 36px;padding: 0 10px;}
.table_wrap .el-select{width: 100%;}

.el-input-number__decrease:hover, .el-input-number__increase:hover ,.el-cascader-node.in-active-path, .el-cascader-node.is-active, .el-cascader-node.is-selectable.in-checked-path ,.el-select-dropdown__item.selected{color: var(--red);}
.input_number .el-input__inner:hover ,.el-input-number__decrease:hover:not(.is-disabled)~.el-input .el-input__inner:not(.is-disabled), .el-input-number__increase:hover:not(.is-disabled)~.el-input .el-input__inner:not(.is-disabled) ,.el-input__inner:focus ,.el-cascader .el-input .el-input__inner:focus, .el-cascader .el-input.is-focus .el-input__inner ,.el-select .el-input.is-focus .el-input__inner ,.el-select .el-input__inner:focus{border-color: var(--red);}
</style>